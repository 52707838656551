import layoutUser from 'components/LayoutUser/locales/en';
import loginPage from 'containers/UserPage/LoginPage/locales/en';
import registerPage from 'containers/UserPage/RegisterPage/locales/en';
import messageStatus from './message/en';
import validation from './validation/en';
import addSubUserPage from 'containers/UserPage/AddUserPage/locales/en';
import subUserPage from 'containers/UserPage/SubUserPage/locales/en';
import accountInfoPage from 'containers/UserPage/AccountInfo/locales/en';
import classListPage from 'containers/UserPage/ClassList/locales/en';
import search from 'components/commons/Search/locales/en';
import accountSettingPage from 'containers/UserPage/Setting/locales/en';
import classDetailsPage from 'containers/UserPage/ClassDetails/locales/en';
import homePage from 'containers/UserPage/DashboardPage/locales/en';
import notiPage from 'containers/UserPage/NotificationPage/locales/en';
import watchPlayListPage from 'containers/UserPage/WatchPlayListPage/locales/en';
import historyViewPage from 'containers/UserPage/HistoryView/locales/en';

const locales = {
  //   layout,
  layoutUser,
  loginPage,
  registerPage,
  homePage,
  addSubUserPage,
  subUserPage,
  accountSettingPage,
  validation,
  messageStatus,
  accountInfoPage,
  classListPage,
  search,
  notiPage,
  classDetailsPage,
  watchPlayListPage,
  historyViewPage
};

export default locales;
