/* eslint-disable import/no-extraneous-dependencies */
import Cookies from 'js-cookie';
import _isString from 'lodash/isString';
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  SCAN_HISTORY
} from '../constants/configs';

const storage = {
  set(key, rawValue, option) {
    const value = _isString(rawValue) ? rawValue : JSON.stringify(rawValue);

    Cookies.set(key, value, option);
  },
  get(key) {
    const value = Cookies.get(key);

    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  },

  remove(key) {
    Cookies.remove(key);
  },
  removeAll() {
    Object.keys(Cookies.get()).forEach(cookieName => {
      Cookies.remove(cookieName);
    });
  },

  setToken(value, option) {
    this.set(ACCESS_TOKEN, value, option);
  },
  getToken() {
    return this.get(ACCESS_TOKEN);
  },

  setRefreshToken(value, option) {
    this.set(REFRESH_TOKEN, value, option);
  },
  getRefreshToken() {
    return this.get(REFRESH_TOKEN);
  },
  getScanHistory() {
    return this.get(SCAN_HISTORY) || [];
  },
  setScanHistory(code, thumbnail, title, company) {
    const newRecord = {
      id: code,
      thumbnail,
      title,
      company
    };
    const currentHistory = this.getScanHistory();

    // Check if record with same ID already exists
    const isDuplicate = currentHistory.some(record => record.id === code);
    if (isDuplicate) {
      // If duplicate found, remove the old one and add new one at the beginning
      const filteredHistory = currentHistory.filter(
        record => record.id !== code
      );
      const updatedHistory = [newRecord, ...filteredHistory].slice(0, 50);
      this.set(SCAN_HISTORY, updatedHistory);
      return updatedHistory;
    }

    // If no duplicate, just add new record at the beginning
    const updatedHistory = [newRecord, ...currentHistory].slice(0, 50);
    this.set(SCAN_HISTORY, updatedHistory);
    return updatedHistory;
  }
};

export default storage;
