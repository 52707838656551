const message = {
  mainTitle: 'Welcome to AHUB',
  codeInputPlaceHolder: 'Enter code',
  submitCode: 'Go',
  toScanPage: 'Scan QR\nto watch',
  scanTitle: 'Scan or upload QR Code to watch Video',
  backBtn: 'Back',
  uploadBtn: 'Upload QR code',
  viewHistory: 'View History'
};

export default message;
